import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";

@Component({
    selector: "app-duration-editor",
    templateUrl: "./duration-editor.component.html",
    styleUrls: ["./duration-editor.component.scss"],
})
export class DurationEditorComponent implements OnChanges {
    @Input() duration?: number;
    @Output() durationChange = new EventEmitter<number>();

    public durationMin = 0;
    public durationSec = 0;

    public ngOnChanges(changes: SimpleChanges) {
        const durationChange = changes.duration;
        if (durationChange?.currentValue) {
            this.durationMin = Math.floor(durationChange.currentValue / 60);
            this.durationSec = durationChange.currentValue % 60;
        }
    }

    public durationUpdate() {
        const totalSeconds = this.durationMin * 60 + this.durationSec;
        this.durationChange.emit(totalSeconds);
    }
}
