<mat-form-field class="minutes">
    <mat-label>Minutes</mat-label>
    <input
        matInput
        name="durationMin"
        type="number"
        step="1"
        min="0"
        [(ngModel)]="durationMin"
        (change)="durationUpdate()"
    />
</mat-form-field>
<mat-form-field class="seconds">
    <mat-label>Seconds</mat-label>
    <input
        matInput
        name="durationSec"
        type="number"
        step="1"
        min="0"
        max="59"
        [(ngModel)]="durationSec"
        (change)="durationUpdate()"
    />
</mat-form-field>
